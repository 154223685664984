<template>
  <div class="jh-dialog-wrap">
    <div class="jh-dialog-header">
      <h1>{{ headerTitle }}</h1>
      <div class="is-right">
        <v-btn class="jh-btn is-icon" title="닫기" @click="clickPopupClose"><i class="jh-icon-close-lg is-white"></i></v-btn>
      </div>
    </div>
    <div class="jh-dialog-body">
      <table class="jh-tbl-detail">
        <colgroup>
          <col width="80px">
          <col>
          <col width="80px">
          <col>
          <col width="80px">
          <col>
          <col width="80px">
          <col>
        </colgroup>
        <tbody>
          <tr>
            <th><label>작성자</label></th>
            <td><v-text-field type="text" class="jh-form is-trns" v-model="txtBoxData.wrtUserV" disabled></v-text-field></td>
            <th><label>작성일시</label></th>
            <td><v-text-field type="text" class="jh-form is-trns" v-model="txtBoxData.wrtDateV" disabled></v-text-field></td>
            <th><label>수정자</label></th>
            <td><v-text-field type="text" class="jh-form is-trns" v-model="txtBoxData.modUserV" disabled></v-text-field></td>
            <th><label>수정일시</label></th>
            <td><v-text-field type="text" class="jh-form is-trns" v-model="txtBoxData.modDateV" disabled></v-text-field></td>
          </tr>
          <tr>
            <th><label>제목</label></th>
            <td colspan="7"><v-text-field type="text" class="jh-form is-trns" v-model="txtBoxData.titleV" disabled></v-text-field></td>
          </tr>
        </tbody>
      </table>
      <div class="jh-editor is-mt-5"><ckeditor ref="editor" v-model="txtAreaV" :config="editorConfig" :read-only="true"></ckeditor></div>
      
      <div class="jh-ui-header is-mt-10">
        <h2>첨부파일</h2>
        <div class="is-right">
        </div>
      </div>
      <v-data-table
        class="jh-grid has-control"
        height="116"
        :headers="gridDataHeaders"
        :items="gridDataText"
        v-model="gridListSelected"
        item-key="index"
        fixed-header
        hide-default-footer
        calculate-widths
        show-select
        :itemsPerPage="-1"
      >
        <template v-slot:item.DOWNLOAD = {item}>
          <div>
            <v-btn v-if="mixin_set_btn($options.name, 'btnDownload')" class="jh-btn is-sm is-fill" @click="fileDownload(item)">다운로드</v-btn>
          </div>
        </template>
      </v-data-table>
    </div>
    <div class="jh-dialog-footer">
      <v-btn class="jh-btn is-md is-light" @click="clickBtnReply" v-if="LVL_NO < 2 && this.mixin_set_btn(this.$options.name, 'btnAnswer')">답변</v-btn>

      <!-- 작성한 사용자만 삭제, 수정 가능하도록 한다. -->
      <v-btn class="jh-btn is-md is-del" v-show="this.wrtUserID == this.txtBoxData.wrtUserV || checkAuth()" @click="clickBtnDel" v-if="this.mixin_set_btn(this.$options.name, 'btnDelete')">삭제</v-btn> 
      <v-btn class="jh-btn is-md is-main"  v-show="this.wrtUserID == this.txtBoxData.wrtUserV" @click="clickBtnModify" v-if="this.mixin_set_btn(this.$options.name, 'btnMody')">수정</v-btn>
    </div>

    <!-- 답글 모달창 -->        
    <v-dialog :retain-focus="false" max-width="900" persistent v-if="DialogE050203P01" v-model="DialogE050203P01">
      <dialog-E050203P01
        :headerTitle="popupTitle"
        :param="E050203P01Param"
        @hide="hideDialog('E050203P01')"
        @Qsave="hideDialog('E050203P01')"
      >
      </dialog-E050203P01>
      <template v-slot:body></template>
    </v-dialog>
    <!-- 답글 모달창 -->
  </div>
</template>

<script>

  //import Ckeditor from "@/components/Ckeditor";
  import {mixin} from "../../mixin/mixin";
  import DialogE050203P01 from "@/views/page/E050203P01";
  import { mapState, mapGetters, mapActions } from "vuex";


  export default {
    name: "MENU_E050203P02", //name은 'MENU_' + 파일명 조합
    props: {
      param: {
        type: Object,
      },
      headerTitle: {
        type: String,
        default: "제목",
      },
    },
    components: {
      DialogE050203P01,
      //Ckeditor,
    },
    mixins:[mixin],

    computed: {
      // 접속된 사용자ID, 사용자이름 받아온다.
      ...mapGetters({
        user_name: "userStore/GE_USER_NAME",
        user_id: "userStore/GE_USER_ID",
      }),
    },
    

    data: () => ({
      DialogE050203P01: false,
      E050203P01Param: {},

      brdNo       : '',
      brdRmkNo    : '',

      objDetails: {},

      txtBoxData : {
        bizV    : '',
        wrtUserV: '',
        wrtDateV: '',
        modUserV: '',
        modDateV: '',
        titleV  : '',
      },
      txtAreaV : '',

      artMsg : {
        // delChk: '현 게시물을 삭제 할 경우 댓글 및 댓글까 전부 조회되지 않습니다. 계속 진행하시겠습니까?',
        delChk: '삭제 할 경우 답변까지 삭제됩니다.<br/>삭제하시겠습니까?',
        selBoxBizChk : '회사구분 은(는) 필수입력입니다.',
        selBoxIdChk  : '게시판명 은(는) 필수입력입니다.',
        noCntData    : '조회된 데이터가 없습니다.',
        newWriteChk  : '조회 후 작성 가능합니다.',
        responseError: '시스템에 오류가 발생하였습니다.'
      },

      editorConfig: {
        extraPlugins: "font, colorbutton, colordialog, openlink, spacingsliders",
        width: "100%",
        height: "350px",
        openlink_enableReadOnly: true,
        openlink_modifier: 0,
        toolbar: [
          // { name: "clipboard", items: [ "Cut", "Copy", "Paste", "-", "Undo", "Redo" ] },//, "PasteText", "PasteFromWord"
          // { name: "editing" }, //, items: [ "Scayt" ]
          { name: "links", items: [ "Link", "Unlink" ] },//, "Anchor"
          { name: "insert", items: [ "Image", "Table", "HorizontalRule", "SpecialChar" ] },
          { name: "forms" },
          { name: "styles", items: [ "Font", "FontSize" ] },
          { name: "styles", items: [ "Styles", "Format" ] },
          { name: "others", items: [ "-" ] },
          "/",
          { name: "colors", items: [ "TextColor", "BGColor", "spacingsliders" ] },
          { name: "basicstyles", items: [ "Bold", "Italic", "Strike" ] }, //, "-", "RemoveFormat"
          { name: "paragraph", items: [ "NumberedList", "BulletedList", "-", "Outdent", "Indent", "-", "Blockquote" ] },
          { name: "document", items: [ "Source" ] },
          { name: "tools", items: [ "Maximize" ] },
          { name: "about", items: [] }
        ],
        toolbarCanCollapse:  true,
        toolbarStartupExpanded: false,
      },

      popupTitle : '',
      footerHideTitle: "닫기",
      gridDataHeaders: [
        { text: "No",       value: "index",   width: "50px",  align: "center", sortable: false,},
        { text: "파일명",    value: "ORIGINAL_FILENAME",          sortable: false,},
        { text: "크기",      value: "FILE_SIZE", width: "80px", align: "center", sortable: false,},
        { text: "다운로드",   value: "DOWNLOAD", width: "70px", align: "center", sortable: false,},
        { text: "다운로드수", value: "DNLOD_CNT", width: "80px", align: "center", sortable: false,},
      ],

      gridDataText    : [],
      gridListSelected: [],

      BRD_QKTY   : [],
      BRD_NEWQKTY: '0',

      wrtUserID: '',
      LVL_NO : '',
    }),
    methods: {
      checkAuth(){//세스템, 관리자
        let atrtGroupNm = this.$store.getters["userStore/GE_USER_ROLE"].atrtGroupId;
        if(atrtGroupNm == '20220826113735368TWBJfPKh' || atrtGroupNm == '20190125141939798TWB52868'){
          return true;
        } else {
          return false;
        }
      },

      //**********************************
      //      게시판 팝업 상세보기 호출
      //**********************************
      async getBrdDetails(selTY) {
        let URL_SET = ''
        let SERVICE_SET = ''
        if (selTY == 'Y'){
          URL_SET = '/api/board/detail-popup/inqire'
          SERVICE_SET = 'board.detail-popup'
        } else if (selTY == 'N'){
          URL_SET = '/api/board/detail-popup/selectNo-noChange/inqire'
          SERVICE_SET = 'board.detail-popup.selectNo-noChange'
        }
        let requestData = {
          headers: {},
          sendData: {}
        }

        requestData.headers["URL"]    = URL_SET;
        requestData.headers["SERVICE"]= SERVICE_SET;
        requestData.headers["METHOD"] = "inqire";
        requestData.headers["ASYNC"]  = false;
        requestData.headers["TYPE"]   = "BIZ_SERVICE";

        requestData.sendData["BRD_ID"]= "2";
        requestData.sendData["BRD_NO"]= this.param.item.BRD_NO;
        requestData.sendData["ASP_NEWCUST_KEY"]= "001";

        let response = await this.common_postCall(requestData, false);
        await this.getBrdDetailsThen(response);
      },

      //***************************************
      //  게시판 팝업 상세보기 호출 callBack 처리
      //***************************************
      async getBrdDetailsThen(response) {
        let resHeader = response.HEADER;
        let resData = response.DATA.at(0);
        this.objDetails = resData;

        if(resHeader.DATA_FLAG == true) {
          this.common_alert(this.alertMsg.responseError, "error");
          return;
        }

        //this.txtBoxData.bizV  = this.param.item.ASP_NEWCUST_KEY;
        this.txtBoxData.bizV  = "001";

        // 등록자: 최초 등록자의 이름(ID)
        this.txtBoxData.wrtUserV = resData["FST_USER_NM"] + "(" + resData["FST_USER_ID"] + ")"

        // 수정자: 마지막 등록자의 이름(ID)
        this.txtBoxData.modUserV = resData["LAST_USER_NM"] + "(" + resData["LAST_USER_ID"] + ")"
        this.txtBoxData.wrtDateV = this.parseDateTime(resData.FST_BRD_DT);
        this.txtBoxData.modDateV = this.parseDateTime(resData.LAST_BRD_DT);
        this.txtBoxData.titleV= this.restoreXSS_CKeditor(resData.BRD_TIT);
        this.txtAreaV = this.restoreXSS_CKeditor(resData.BRD_RMK);

        this.LVL_NO = resData.LVL_NO;

        // 첨부파일 dataList 호출
        let resList = await this.mixin_getFileList(resData.FILE_GROUP_KEY);
        this.setFileList(resList)

        //console.log(this.$refs.editor.instance.getData())
      },

      //*********************************
      //        첨부파일 리스트 set
      //*********************************,
      setFileList(listData) {
        this.gridDataText = [];

        for(let i in listData) {
          let tempItem = listData[i];
          tempItem["FILE_SIZE"] = this.mixin_getFileSize(listData[i].FILE_SIZE);
          tempItem["index"] = ++i;

          console.log("chs11:::::::::::", tempItem);
          this.gridDataText.push(tempItem);
        }
      },

      //*********************************
      //        첨부파일 다운로드
      //*********************************
      async fileDownload(item){
        console.log("chs22:::::::::::", item);
        let busiType = "bbs";   //palette, phone, chat, km, bbs
        let pathType = "files"; //publics,files,images,videos,inqrytypeimage
        await this.mixin_fileDownload(item, busiType, pathType)
      },

      //***************************************
      //         삭제버튼 모달 이벤트
      //***************************************
      clickBtnDel() {
        this.common_confirm(this.artMsg.delChk, this.clickDelY, null,
                                                this.clickDelN, null, "error");
      },

      async clickDelY() {
        let requestData = {
          headers: {},
          sendData: {}
        }

        requestData.headers["URL"]    = "/api/board/detail-popup/delete";
        requestData.headers["SERVICE"]= "board.detail-popup";
        requestData.headers["METHOD"] = "delete";
        requestData.headers["ASYNC"]  = true;
        requestData.headers["TYPE"]   = "BIZ_SERVICE";

        requestData.sendData["BRD_ID"]         = "2";
        requestData.sendData["BRD_NO"]         = this.param.item.BRD_NO;
        requestData.sendData["PROC_ID"]        = this.$store.getters['userStore/GE_USER_ID'];
        requestData.sendData["ASP_NEWCUST_KEY"]= "001";

        let response = await this.common_postCall(requestData);

        if (response.HEADER.ERROR_FLAG == false) {
          this.common_alert("게시물이 삭제되었습니다.", "done");
        } else {
          this.common_alert(this.alertMsg.responseError, "error");
        }
        this.clickPopupClose();
      },

      clickDelN() {
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: false,
        });
      },

      //***************************************
      //           답변 Button 팝업
      //***************************************
      clickBtnReply() {
        this.popupTitle = "답변 작성"
        this.E050203P01Param = {
          flag     : 'reply',
          userId   : this.$store.getters['userStore/GE_USER_ID'],
          bizCd    : this.param.bizCd,
          bizNm    : this.param.bizNm,
          item     : this.param.item,
        };
        this.showDialog('E050203P01')
      },

      //***************************************
      //            수정 Button 팝업
      //***************************************
      clickBtnModify() {
        this.popupTitle = "글 수정"
        this.E050203P01Param = {
          flag     : 'modify',
          userId   : this.$store.getters['userStore/GE_USER_ID'],
          bizCd    : this.param.bizCd,
          bizNm    : this.param.bizNm,
          item     : this.param.item,
          txtArea  : this.txtAreaV,
        };
        this.showDialog('E050203P01');
      },

      //***************************************
      //             팝업 창 닫기
      //***************************************
      clickPopupClose() {
        this.$emit('hide')
      },

      //***************************************
      //         Dialog(모달) 이벤트
      //***************************************
      showDialog(menu) {
        console.log("showDialog 2dep")
        this[`Dialog${menu}`] = true;
      },
      hideDialog(menu) {
        console.log("hideDialog 2dep")
        this[`Dialog${menu}`] = false;
        this.getBrdDetails('N');
      },

      //***************************************
      //         dbData 날짜 형식 변경
      //***************************************
      parseDateTime(data) {
        let year = [data.substr(0, 4), data.substr(4, 2), data.substr(6, 2)].join('/');
        let hour = [data.substr(8, 2), data.substr(10, 2), data.substr(12, 2)].join(':');

        return year+' '+hour;
      },
    },
    
    created() {
    },

    async mounted() {
      this.txtBoxData.bizV  = this.param.bizCd;
      this.txtBoxData.wrtUserV = this.param.userId;
      this.wrtUserID = this.user_name + "("+ this.user_id +")"

      this.getBrdDetails('Y');

      let codeName = ["BRD001"];
      let common_code = await this.mixin_common_code_get_all(codeName);
      this.BRD_QKTY = [...this.BRD_QKTY, ...common_code[0].group_value];
    },
  };
</script>

<style></style>
