<template>
  <div class="jh-container">
    <div class="jh-ui-header">
      <h1>자유게시판</h1>
      <div class="is-right">
      </div>
    </div>
      <!-- 조회 -->
      <div class="jh-search-form">
        <table>
          <colgroup>
            <col width="30px">
            <col width="400px">
            <col width="70px">
            <col width="120px">
            <col>
          </colgroup>
          <tr>
            <th><label>제목</label></th>
            <td>
              <v-text-field class="jh-form" v-model="txtFldTitleV" @keyup.enter="clickGetList" placeholder="제목"></v-text-field>
            </td>
            <th><label>등록자</label></th>
            <td>
              <v-text-field class="jh-form" v-model="txtFldRegV" @keyup.enter="clickGetList" placeholder="등록자"></v-text-field>
            </td>
            <td class="has-search">
              <v-btn class="jh-btn is-search" @click="clickGetList">조회</v-btn>
            </td>
          </tr>
        </table>
      </div>
      <!-- 조회 -->

      <div class="jh-form-wrap">          
        <div class="jh-ui-header">
          <h2>자유게시판 리스트</h2>
          <div class="is-right">
            <v-btn class="jh-btn is-border-blue" @click="clickNewWrite" v-if="this.mixin_set_btn(this.$options.name, 'btnNew')">새글 등록</v-btn>
          </div>
        </div>
          
        <data-tables
          ref="dataTable"
          dataTableClass="has-control"
          :data-table-options="dataTableOptions"
          @click:row="rowClick"
          @dblclick:row="dbClickListRow"
          :paginationOptions="paginationOptions"
          v-model="gridListItemSelected"
        >
          <template v-slot:item.BRD_TIT="{ item }" >
            <td>
              <div class="jh-align-middle">
                <!-- 댓글, 새글 아이콘 표시 -->
                <span v-html="item.NBSP"></span>
                <i class="jh-icon-reply is-red is-mr-5" v-if="item.LVL_NO > 0"></i>
                {{ item.BRD_TIT }}
                <i class="jh-icon-new is-ml-5" v-if="item.BRD_TIT_NEW == 'Y'"></i>
              </div>
            </td>
          </template>
        </data-tables>
      </div>

      <!-- 새글 등록 모달창 -->
      <v-dialog :retain-focus="false" max-width="900" persistent v-if="DialogE050203P01" v-model="DialogE050203P01" :eager="true">
        <dialog-E050203P01
          headerTitle="새글 등록"
          :param="E050203P01Param"
          @hide="hideDialog('E050203P01')"
          @Qsave="clickGetList(), hideDialog('E050203P01')"
        >
        </dialog-E050203P01>
        <template v-slot:body></template>
      </v-dialog>

      <!-- 게시글 상세 모달창 -->
      <v-dialog :retain-focus="false" max-width="900" persistent v-if="DialogE050203P02" v-model="DialogE050203P02" :eager="true">
        <dialog-E050203P02
          headerTitle="상세내용보기"
          :param="E050203P02Param"
          @hide="hideDialog('E050203P02')"
          @Qsave="clickGetList(), hideDialog('E050203P02')"
        >
        </dialog-E050203P02>
        <template v-slot:body></template>
      </v-dialog>
  </div>
</template>

<script>
  import api from "@/store/apiUtil";
  import { mixin } from "@/mixin/mixin";
  import DialogE050203P01 from "./E050203P01.vue";
  import DialogE050203P02 from "./E050203P02.vue";
  import dataTables from '@/components/DataTables.vue';

  export default {
    name: "MENU_E050203", //name은 'MENU_' + 파일명 조합
    components: {
      DialogE050203P01,
      DialogE050203P02,
      dataTables,
    },
    mixins: [mixin],
    data() {
      return {
        brdId: "2",

        DialogE050203P01: false,
        DialogE050203P02: false,
        E050203P01Param: {},
        E050203P02Param: {},

        txtFldTitleV: "",
        txtFldRegV: "",

        // 페이지 리스트
        page: 1,
        pageCount: 0,
        itemsPerPage: 20,
        totalVisible: 10,

        gridListItemSelected: [],
        gridListDataSelect: [],
        gridListDataText: [],
        gridListDataHeaders: [
          {
            text: "No",
            align: "center",
            value: "ROWNUM",
            width: "60px",
            sortable: false,
          },
          {
            text: "제목",
            value: "BRD_TIT",
            align: "center",
            sortable: false,
          },
          {
            text: "첨부파일",
            value: "FILE_CNT",
            align: "center",
            width: "5%",
            sortable: false,
          },
          {
            text: "등록자",
            value: "FST_USER_NM",
            align: "center",
            width: "10%",
            sortable: false,
          },
          {
            text: "등록일시",
            value: "FST_BRD_DT",
            align: "center",
            width: "12%",
            sortable: false,
          },
          // {
          //   text: "수정일시",
          //   value: "LAST_BRD_DT",
          //   align: "center",
          //   width: "12%",
          //   sortable: false,
          // },
          {
            text: "조회수",
            value: "SELECT_NO",
            align: "center",
            width: "4%",
            sortable: true,
          },
        ],

        dataTableOptions: {
          fixedHeader: true,
          hideDefaultFooter: true,
          height: '600px',
          items: undefined,
          itemKey: 'ROWNUM',
          itemsPerPage: 50,
          noDataText: "조회된 결과가 없습니다.",
          page: 1,
          singleSelect: true,
          disableSort: true, // 전체 정렬 안되게 한다.
          
          // 화면 오픈 시 리스트 조회
          load: this.getGridList
        },

        paginationOptions: {
          totalVisible: 10
        },

        alertMsg: {
          searchChk    : "제목 또는 등록자를 입력해 주세요.",
          selBoxIdChk  : "게시판명 은(는) 필수입력입니다.",
          noCntData    : "조회된 데이터가 없습니다.",
          newWriteChk  : "조회 후 작성 가능합니다.",
          responseError: "시스템에 오류가 발생하였습니다.",
        },

        requestData: {}
      };
    },
    
    // 화면 오픈시 리스트 조회
    created() {
      this.setDataTableParams();

      this.dataTableOptions.headers = this.gridListDataHeaders;
      this.dataTableOptions.items = this.gridListDataText;
    },

    methods: {

      // 조회버튼 클릭 데이터 조회
      clickGetList() {
        this.setDataTableParams();

        if (this.dataTableOptions.page > 1) this.dataTableOptions.page = 1;
          else this.dataTableInstance.loadData();
      },

      setDataTableParams: function() {
        this.requestData = {
          headers: {},
          sendData: {},
        };

        this.requestData.headers["URL"] = "/api/board/list-paging";
        this.requestData.headers["SERVICE"] = "board";
        this.requestData.headers["METHOD"] = "list-paging";
        this.requestData.headers["ASYNC"] = false;
        this.requestData.headers["TYPE"] = "BIZ_SERVICE";

        this.requestData.sendData["BRD_ID"] = "2";
        this.requestData.sendData["ASP_NEWCUST_KEY"] = "001";

        this.requestData.sendData["BULTN_FR_DT"] = "";
        this.requestData.sendData["BULTN_TO_DT"] = "";
        this.requestData.sendData["NOTICE_STATUS"] = "";

        this.requestData.sendData["BRD_TIT"] = this.restoreXSS_CKeditorDec(this.txtFldTitleV);
        this.requestData.sendData["FST_USER_NM"] = this.txtFldRegV;
        this.requestData.sendData["LAST_USER_NM"] = this.txtFldModV;

        //부서정보 처리
        let chkGroup = this.$store.getters["authStore/GE_AUTH_LOGIN_DATA"].AUTHOR_GROUP_NM_LIST;
        if(chkGroup.indexOf("시스템") == -1){
          this.requestData.sendData["USER_ATTR_A"] = this.$store.getters["userStore/GE_USER_ROLE"].USER_ATTR_A;
        }
        
      },
      
      // 조회버튼 클릭 데이터 호출     
      async getGridList(loadOptions) {
        // header 세팅
        this.requestData.headers["ROW_CNT"] = loadOptions.itemsPerPage;
        this.requestData.headers["PAGES_CNT"] = loadOptions.page;

        // 컬럼 정렬시 사용 (필요시 주석 해제하여 사용, 쿼리랑 컬럼값 맞춰야 함 BoardMapper_MariaDB.xml)
        // if (loadOptions.sortBy && loadOptions.sortBy.length > 0)
        //   this.requestData.sendData["SORT_ORDR"] = loadOptions.sortBy[0];
        // else this.requestData.sendData["SORT_ORDR"] = undefined;
        // if (loadOptions.sortDesc && loadOptions.sortDesc.length > 0)
        //   this.requestData.sendData["SORT_DRCT"] = loadOptions.sortDesc[0] ? 'desc' : 'asc';
        // else this.requestData.sendData["SORT_DRCT"] = undefined;

        return this.common_postCall(this.requestData).then(response => {
          let resHeader = response.HEADER;
          let resData = response.DATA;

          // 데이터 조회 오류 시 alert
          if (resHeader.ERROR_FLAG == true) {
            this.common_alert(this.alertMsg.responseError, "error");
            return false;
          }
          if (resData != 0) {
            let idx = 1;
            for (let i in resData) {
              let id = resData[i].BRD_NO;
              resData[i]["id"] = id;
              resData[i]["index"] = idx++;
              resData[i]["BRD_TIT"] = this.restoreXSS_CKeditor(resData[i]["BRD_TIT"]);

              // 등록자: 최초 등록자의 이름(ID)
              resData[i]["FST_USER_NM"] = resData[i]["FST_USER_NM"] + "("+ resData[i]["FST_USER_ID"] +")";
              resData[i]["FST_BRD_DT"] = this.parseDateTime(
                resData[i]["FST_BRD_DT"]
              );
              resData[i]["LAST_BRD_DT"] = this.parseDateTime(
                resData[i]["LAST_BRD_DT"]
              );

              if (
                resData[i]["FILE_CNT"] == "" ||
                resData[i]["FILE_CNT"] == "undefined" ||
                resData[i]["FILE_CNT"] == "0"
              ) {
                resData.file = false;
              } else {
                resData.file = true;
              }
            }
          }
          return {
            data: resData,
            totalCount: resHeader.TOT_COUNT
          }
        });
        
      },

      clickNewWrite() {
        this.E050203P01Param = {
          flag: "new",
          userId: this.$store.getters["userStore/GE_USER_ID"],
          item: {
            BRD_ID: "2",
          },
          BRD_ID: "2",
        };

        this.showDialog("E050203P01");
      },

      // row 클릭이벤트
      rowClick: function (item, row) {
        row.select(true);
      },

      // row 더블 클릭이벤트
      dbClickListRow(e, { item }) {
        this.E050203P02Param = {
          userId: this.$store.getters["userStore/GE_USER_ID"],
          listData: this.gridListDataText,
          item: item,
        };

        this.showDialog("E050203P02");
      },

      parseDateTime(data) {
        let year = [data.substr(0, 4), data.substr(4, 2), data.substr(6, 2)].join(
          "-"
        );
        let hour = [
          data.substr(8, 2),
          data.substr(10, 2),
          data.substr(12, 2),
        ].join(":");

        return year + " " + hour;
      },

      showDialog(menu) {
        console.log("showDialog");
        this[`Dialog${menu}`] = true;
      },

      hideDialog(menu) {
        console.log("hideDialog");
        this[`Dialog${menu}`] = false;
        this.dataTableInstance.loadData();
      },

    },

    mounted() {
    },

    computed: {
      dataTableInstance: function() {
        return this.$refs.dataTable;
      },
    },

    watch: {
    },
  };
</script>

<style>
  .v-dialog:not(.v-dialog--fullscreen){max-height: fit-content;}
</style>
